@import "/src/@fuse/scss/fuse";

fuse-navigation {
	display: flex;
	flex: 1 0 auto;

	> .nav {
		margin: 0;
		padding: 0;
		width: 100%;
	}
}
