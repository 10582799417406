.fuse-material-color-picker-menu {
	width: 245px;

	.mat-mdc-menu-content {
		padding: 0;

		.views {
			display: flex;
			flex-direction: column;
			min-height: 165px;

			.view {
				overflow: hidden;

				.colors {
					padding: 1px 0 0 0;
					margin-left: -1px;

					.color {
						width: 40px;
						height: 40px;
						margin: 0 0 1px 1px;
						border-radius: 0;
						cursor: pointer;
						transition: border-radius 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

						&:hover {
							border-radius: 20%;
						}

						&.selected {
							border-radius: 50% !important;
						}
					}
				}
			}
		}
	}
}
